<template>
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12" nav-class="nav-left">
    <h1 class="element-invisible">Perfil de usuario</h1>
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Info</span>
      </template>
      <user-info @onCreated="$router.push('/')" @onStoryLoaded="$router.push('/')"></user-info>
    </b-tab>
    <!--/ general tab -->

    <!-- password -->
    <b-tab v-if="user._id">
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Change Password</span>
      </template>
      <user-password @onPasswordChanged="onPasswordChanged"></user-password>
    </b-tab>
    <!--/ password -->

    <!-- delete -->
    <!--     <b-tab v-if="user._id">
      <template #title>
        <feather-icon icon="AlertCircleIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Delete</span>
      </template>
      <userDelete @onDeleted="onDeleted"></userDelete>
    </b-tab> -->
    <!--/ delete -->
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import store from '../../store'
import { Session } from '../../plugins/session'

const syncUser = {}
const getPageData = (routeTo, next) => {
  console.log('antes :>> ')
  store
    .dispatch('userStore/readById', Session.user._id)
    .then(response => {
      syncUser.email = response.email
      syncUser.fullname = response.fullname
      syncUser.tel = response.tel
      syncUser.img = response.img
      syncUser.altTel = response.altTel
      syncUser.ubicacion = response.ubicacion
    })
    .then(() => {
      return next()
    })
    .catch(() => {})
}
export default {
  components: {
    BTab,
    BTabs,
    userInfo: () => import('../components/user/userInfo.vue' /* webpackChunkName: "userInfo" */),
    userPassword: () => import('../components/user/userPassword.vue' /* webpackChunkName: "userPassword" */),
    userDelete: () => import('../components/user/userDelete.vue' /* webpackChunkName: "userDelete" */)
  },
  metaInfo: {
    title: 'Usuario',
    titleTemplate: '%s | Publica tu mascota'
  },
  data: () => ({
    deleteUser: false
  }),
  computed: {
    user() {
      return this.$session.user
    }
  },
  methods: {
    onPasswordChanged() {
      this.$store
        .dispatch('clearAll')
        .then(() => this.$router.push('/login'))
        .catch(() => {})
    },
    onDeleted() {
      this.$store
        .dispatch('clearAll')
        .then(() => this.$router.push('/login'))
        .catch(() => {})
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log('antes :>> ')
  
    getPageData(to, next)
  },
  beforeRouterUpdate(to, from, next) {
    getPageData(to, next)
  }
}
</script>

<style lang="scss" scoped>
</style>
